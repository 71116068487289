import Toast from 'interfaces/toast/toast'
import { toast, ToastContainer, ToastOptions } from 'react-toastify'
import styled from 'styled-components'

const defaultSettings: ToastOptions = {
	position: 'bottom-center',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
}

// TODO: Using react-toastify instead of toasted notes at the moment
// Needs a bit of cleaning up here
export const toastMessage = (toastData: Toast, overrideSettings?: ToastOptions) => {
	const settings = { ...defaultSettings, ...overrideSettings }
	if (toastData.type === 'info' || !toastData.type) toast.info(toastData.message, settings)
	if (toastData.type === 'error') toast.error(toastData.message, settings)
	if (toastData.type === 'warning') toast.warning(toastData.message, settings)
	if (toastData.type === 'success') toast.success(toastData.message, settings)
}

export const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast {
		padding: 1.5rem 3rem;
		font-weight: 600;
	}
	.Toastify__toast--default,
	.Toastify__toast--info {
		background-color: ${({ theme }) => theme.colors.primary};
	}
	.Toastify__toast--success {
		background-color: ${({ theme }) => theme.colors.green};
	}
	.Toastify__toast--warning {
		background-color: ${({ theme }) => theme.colors.warning};
	}
	.Toastify__toast--error {
		background-color: ${({ theme }) => theme.colors.secondary};
	}
`

export default toastMessage
